@use '@angular/material' as mat;

@include mat.core();

$app-primary: mat.define-palette(mat.$indigo-palette,500);
$app-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);
$app-success: mat.define-palette(mat.$green-palette);

$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
    success: $app-success,
  )
));

// @include angular-material-theme($app-theme);
@include mat.all-component-themes($app-theme);

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #1e2020;
}

.login { width: 100% }

$color-green: #68F3A8;

$pixels: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50, 75, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 200, 250, 300, 350, 400);
$sides: (top, bottom, left, right);


$body-level-font: mat.define-typography-config(
  $font-family: "Roboto, Helvetica Neue, sans-serif",
);

// $my-typography: mat.define-typography-config();
@include mat.typography-hierarchy($body-level-font);

@each $pixel in $pixels {
  .font-size-#{$pixel} {
    font-size: #{$pixel}px !important;
  }

  .m-#{$pixel} {
    margin: #{$pixel}px !important;
  }

  .p-#{$pixel} {
    padding: #{$pixel}px !important;
  }

  .w-#{$pixel} {
    width: #{$pixel}px !important;
  }

  .max-width-#{$pixel} {
    max-width: #{$pixel}px !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$pixel} {
      margin-#{$side}: #{$pixel}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$pixel} {
      padding-#{$side}: #{$pixel}px !important;
    }
  }

  .px-#{$pixel} {
    padding-left: #{$pixel}px !important;
    padding-right: #{$pixel}px !important;
  }

  .py-#{$pixel} {
    padding-top: #{$pixel}px !important;
    padding-bottom: #{$pixel}px !important;
  }

  .mx-#{$pixel} {
    margin-left: #{$pixel}px !important;
    margin-right: #{$pixel}px !important;
  }

  .my-#{$pixel} {
    margin-top: #{$pixel}px !important;
    margin-bottom: #{$pixel}px !important;
  }
}

.mat-drawer-container {
  background: #ffffff;
}

.mat-header-cell {
  color: #000000 !important;
  font-size: 14px;
}

.btn {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  font-weight: 500;
  border: 0;
  cursor: pointer;
}

.btn-blank {
  border: 1px solid #000000;
  background: #ffffff;
  color: #000000;
}

.btn-green {
  background-color: $color-green;
  color: #000000;
}

.rounded-none	{ border-radius: 0; }
.rounded-sm	{ border-radius: 0.125rem; }
.rounded { border-radius: 0.25rem; }
.rounded-md	{ border-radius: 0.375rem; }
.rounded-lg	{ border-radius: 0.5rem; }
.rounded-xl	{ border-radius: 0.75rem; }
.rounded-2xl { border-radius: 1rem; }
.rounded-3xl { border-radius: 1.5rem; }
.rounded-full { border-radius: 9999px; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.no-underline { text-decoration: none; }

.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }
.normal-case { text-transform: none; }

.font-hairline { font-weight: 100; }
.font-thin { font-weight: 200; }
.font-light { font-weight: 300; }
.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.font-extrabold { font-weight: 800; }
.font-black { font-weight: 900; }
.cursor-pointer { cursor: pointer; }

.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .radio + .radio {
    margin-left: 20px;
  }

  .form-controls {
    display: flex;
    justify-content: flex-end;

    .cancel-link {
      margin-right: 20px;
    }
  }
}

.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }
.align-center { align-items: center; }
.align-end { align-items: end; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around	{ justify-content: space-around; }
.justify-evenly	{ justify-content: space-evenly }

.w-full { width: 100%; }
.w-1\/2 { width: 50%; }
.w-1\/3 { width: 33%; }
.w-1\/4 { width: 25%; }
.w-1\/5 { width: 20%; }
.w-1\/6 { width: 16.666667%; }
.w-2\/6 { width: 33.333333%; }
.w-3\/6 { width: 50%; }
.w-4\/6 { width: 66.666667%; }
.w-5\/6 { width: 83.333333%; }

.align-baseline { vertical-align: baseline; }

.error {
  color: #F54337;
}
